import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import Link from '../components/Common/Link'
import List from '../components/Common/List'

import * as s from '../pages/news.module.scss'

const BimOnTheConstructionSite = () => {
  return (
    <>
      <SEO
        title="BIM on the construction site"
        description="BIM in construction is an integral part of the industry. The creation and use of BIM models with architects, engineers, contractors, builders and clients seeks to improve the construction process, minimize costs and increase the performance and quality of work performed"
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          BIM on the construction site
        </Typography>
        <Typography variant="h2" size={24} mb={16}>
          About the author
        </Typography>
        <List>
          <li>
            Pavel Goretskiy is a BIM manager at SIGNAX and is responsible for
            the onsite implementation of BIM and digital services in the MENA
            region
          </li>
          <li>
            Pavel graduated from the State Power Engineering Institute, where
            specialized in Power Engineering, earning a Master's Degree
          </li>
          <li>
            Pavel has more than 5 years of construction experience and BIM
            implementations in Residential housing and Industrial Facilities
          </li>
          <li>
            Now he also acts as a team coordinator and has several publications
            in specialized journals
          </li>
        </List>
        <Typography variant="body1">
          More about Pavel at{' '}
          <Link
            href="https://www.linkedin.com/in/pavel-goretskiy-809b03249"
            rel="nofollow noreferrer"
            target="_blank"
          >
            LinkedIn
          </Link>
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={600}
            src="../assets/images/articles/bim-on-the-construction-site/image-1.png"
            alt="Pavel Goretskiy"
            title="Pavel Goretskiy"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" size={24} mb={16}>
          Introduction
        </Typography>
        <Typography variant="body1">
          Today, the whole construction industry is undergoing a transformation
          through the development and implementation of Building Information
          Modeling (BIM). Building Information Modeling (BIM) is a digital
          technology utilized in construction that entails the creation of a
          virtual model of a building or infrastructure project before its
          physical construction, which is then employed throughout the entire
          process, encompassing the construction and operation phases. This
          virtual model contains plenty of information about the project:
          materials, cost, vendor information, and linked scopes of work for
          advanced project control. The implementation of BIM by architects,
          engineers, contractors, builders, and clients aims to enhance the
          construction process, minimize expenses, and boost productivity.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/bim-on-the-construction-site/image-2.png"
            alt="The implementation of BIM by architects, engineers"
            title="The implementation of BIM by architects, engineers"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" size={24} mb={16}>
          The crucial role of BIM in the construction industry development
        </Typography>
        <Typography variant="body1">
          The construction industry has traditionally been slow to adopt new
          technologies and processes. As construction projects become more
          intricate and efficiency becomes paramount, BIM has become crucial for
          architects, engineers, contractors, builders, and clients. By enabling
          the creation of 3D models, facilitating efficient management, and
          promoting effective collaboration, BIM is indispensable for
          construction professionals.
        </Typography>
        <Typography variant="body1">
          Before the advent of BIM, construction professionals relied on 2D
          drawings and manual calculations to design and construct buildings.
          This often led to errors, rework, and delays in construction projects.
          The utilization of BIM has transformed the construction process as it
          offers a digital medium for construction experts to envision their
          projects in three dimensions and detect any potential problems or
          discrepancies before the commencement of construction.
        </Typography>
        <Typography variant="body1">
          Government regulations and mandates are also driving the adoption of
          BIM. Many countries have implemented regulations that require the use
          of BIM on public projects. This has led to increased adoption of BIM
          in the whole construction industry, particularly on construction
          sites.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/bim-on-the-construction-site/image-3.png"
            alt="The crucial role of BIM in the construction industry development"
            title="The crucial role of BIM in the construction industry development"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" size={24} mb={16}>
          10 BIM-uses for construction
        </Typography>
        <Typography variant="body1">
          Various technologies and approaches can be utilized to implement BIM.
          Still, the prevalent method involves utilizing BIM software like
          Autodesk Revit for designing and Autodesk Construction Cloud and
          SIGNAX services for construction. This software allows construction
          experts to employ 3D models of their projects and collaborate with
          their team members in real-time while on-site.
        </Typography>
        <Typography variant="body1">
          Let's review 10 on-site BIM technologies and approaches, also known as
          BIM uses:
        </Typography>
        <ul className="ml-4" style={{ listStyle: 'decimal' }}>
          <li>
            <Typography variant="body1" className="m-0">
              BIM models enhance collaboration and communication among onsite
              teams by utilizing cloud-based tools such as SIGNAX DOCS or
              Autodesk Construction Cloud common data environments. With the
              help of mobile apps like SIGNAX DASHBOARD, team members can easily
              access project information on their handheld devices.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              With BIM tools like Revit, Docs, and SIGNAX TOOLS, model-based
              cost estimation automates the process of quantifying and applying
              costs. This enables estimators to concentrate on more valuable
              factors.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              By utilizing BIM tools, preconstruction planning and visualization
              can be achieved, thereby reducing the need for costly and
              time-consuming modifications in the future.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              BIM enables better coordination and clash detection with
              Navisworks and SIGNAX TOOLS, reducing reworks needed on any given
              job.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              BIM tools offer multiple cost-saving benefits, including decreased
              tender risk premiums, insurance expenses, variations, and claims.
              Additionally, they minimize labor costs associated with
              documentation tasks and misunderstandings. With real-time
              collaboration and a centralized document repository, the
              likelihood of utilizing obsolete information is reduced.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              BIM saves time by allowing design and documentation to happen
              simultaneously, improving accuracy in scheduling and reducing
              setbacks.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              The utilization of BIM results in enhanced productivity and
              reduced waste through the implementation of prefabrication
              techniques.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              BIM enhances construction safety by identifying potential hazards
              and planning logistics in advance.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              BIM leads to better building quality by coordinating teams and
              allowing for early identification of structural deficiencies and
              aesthetic choices.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              BIM improves building management and facilitates the transfer of
              ownership by creating a continuous digital record of building data
              that can be used for post-occupancy purposes, including the
              potential creation of a digital replica of the building.
            </Typography>
          </li>
        </ul>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/bim-on-the-construction-site/image-4.png"
            alt="BIM-uses for construction"
            title="BIM-uses for construction"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" size={24} mb={16}>
          How to implement BIM on-site - our cheatsheet
        </Typography>
        <Typography variant="body1">
          To implement BIM on a construction site, construction professionals
          should follow these steps:
        </Typography>
        <ul className="ml-4" style={{ listStyle: 'decimal' }}>
          <li>
            <Typography variant="body1" className="m-0">
              Define what exact BIM uses you want to implement;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              Choose a construction BIM software that meets the needs of the
              project, the pilot project, and the consulting company for support
              (if needed);
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              Create a construction BIM model out of the design one;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              Proof of Concept and Piloting;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              Train personnel;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              Continuous improvement - day-to-day BIM will cover more and more
              processes;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className="m-0">
              Continuously updating the BIM model during the construction
              process is crucial to ensuring that all parties are using the
              latest information.
            </Typography>
          </li>
        </ul>
        <Typography variant="body1">
          You can find more details on our website or just DM us.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/bim-on-the-construction-site/image-5.png"
            alt="How to implement BIM on-site - our cheatsheet"
            title="How to implement BIM on-site - our cheatsheet"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" size={24} mb={16}>
          Conclusion
        </Typography>
        <Typography variant="body1">
          BIM is revolutionizing the approach to constructing projects. It
          empowers construction experts to produce 3D models, streamline project
          management, and enhance collaboration while minimizing risks. With the
          aid of BIM, construction professionals can decrease inaccuracies,
          economize time and finances, and guarantee that their projects are
          finalized on schedule and within the designated budget.
        </Typography>
        <Typography variant="body1">
          As technology continues to evolve, we can expect to see even more
          innovative uses of BIM in the construction industry. BIM is here to
          stay, and those who embrace it will be better positioned to compete in
          an increasingly competitive market.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default BimOnTheConstructionSite
